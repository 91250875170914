import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "resources"
    }}><a parentName="h1" {...{
        "href": "#resources"
      }}>{`Resources`}</a></h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://internetcomputer.org/docs/current/developer-docs/setup/pulling-canister-dependencies/"
        }}>{`DFX Pull Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./making-a-canister-pullable"
        }}>{`Making a canister pullable`}</a></li>
    </ul>
    <h1 {...{
      "id": "background"
    }}><a parentName="h1" {...{
        "href": "#background"
      }}>{`Background`}</a></h1>
    <p>{`The new DFX Pull feature allows you to pull a canister running on the Internet Computer simply using its canister ID. In practice, it will look something like this:`}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// dfx.json`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"canisters"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"whoami"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"pull"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"id"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"ivcos-eqaaa-aaaab-qablq-cai"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`"Pull" type canisters will share the same canister ID as they have on the Internet Computer mainnet during local development. The canister can also provide its own candid file, and a url to pull a wasm module from. Dfx can do all this automatically, as long as the canister has chosen to provide this information in its public metadata.`}</p>
    <h1 {...{
      "id": "how-to-use-dfx-pull"
    }}><a parentName="h1" {...{
        "href": "#how-to-use-dfx-pull"
      }}>{`How to use DFX Pull`}</a></h1>
    <p>{`Make sure you are using `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` 0.14.x or higher. You can check your version by running `}<inlineCode parentName="p">{`dfx --version`}</inlineCode>{`, and you can upgrade to the latest release by running `}<inlineCode parentName="p">{`dfx upgrade`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "configuring-your-canister"
    }}><a parentName="h2" {...{
        "href": "#configuring-your-canister"
      }}>{`Configuring your canister`}</a></h2>
    <p>{`You will list the canister by whatever name you want to use in your project, and then specify the canister ID. If the canister depends on other canisters, these can also be pulled automatically.`}</p>
    <h2 {...{
      "id": "pulling-a-canister"
    }}><a parentName="h2" {...{
        "href": "#pulling-a-canister"
      }}>{`Pulling a canister`}</a></h2>
    <p>{`Once you have the canister configured, you can pull it by running two commands:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`dfx deps pull`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`dfx deps init`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`dfx deps deploy`}</inlineCode></li>
    </ul>
    <p>{`This will create a `}<inlineCode parentName="p">{`deps`}</inlineCode>{` folder with some metadata about the canisters, and allow you to cover any `}<inlineCode parentName="p">{`init`}</inlineCode>{` arguments that the canister requires. With the `}<inlineCode parentName="p">{`whoami`}</inlineCode>{` example above, no such arguments are required, so you can just run `}<inlineCode parentName="p">{`dfx deploy`}</inlineCode>{`, and then `}<inlineCode parentName="p">{`dfx canister call whoami whoami`}</inlineCode>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: As of 0.14.4, `}<inlineCode parentName="p">{`dfx deploy`}</inlineCode>{` does not automatically deploy your dependencies. You should run `}<inlineCode parentName="p">{`dfx deps deploy`}</inlineCode>{` before `}<inlineCode parentName="p">{`dfx deploy`}</inlineCode>{` for best results.`}</p>
    </blockquote>
    <p>{`That's all you should need to get going! For next steps, check out the `}<a parentName="p" {...{
        "href": "./making-a-canister-pullable"
      }}>{`Making a canister pullable`}</a>{` guide.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      